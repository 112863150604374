.LicencesTitle {
  margin-top: 5px;
}

.Header {
  margin-top: 10px;
  margin-bottom: 30px;
}

.AddLicenceButton {
  margin-top: 5px;
}

.ActionButtons {
  margin-top: 5px;
}

.AssignButton {
  margin-left: 5px;
}

.DisableButton {
  background-color: rgba(194, 214, 215, 0.62);
  color: white;
  width: 116px;
}

.DeleteButton {
  margin-left: 5px;
  background-color: #F76F8E !important;
  color: white;
  width: 116px;
}
