.InputText_Label {
	cursor : pointer;
	font-size : 16px;
	line-height : 19px;
	color : #707070;
	display : block;
	margin-bottom : 9px;
}

.InputText_Wrapper {
	position : relative;
}

.InputText_Input {
	width : 100%;
	height : auto;
	background-color : #FFFFFF;
	border-color : #707070;
	border-radius : 5px;
	border-width: 1px;
	text-align : left;
	display : block;
	padding : 13px 12px 13px 12px;
	font-size : 16px;
	line-height : 19px;
	color : #707070;
}

