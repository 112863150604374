.Wrapper {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.FormSignIn {
    width: 100%;
    max-width: 330px;
    padding: 15px;
    margin: auto;
}

.FormSignIn input[type="text"] {
    margin-bottom: -11px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

.FormSignIn input[type="password"] {
    margin-bottom: 10px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.ButtonPrimary {
    width: 100%;
}

.ErrorMessage {
    padding-top: 20px;
    color: #F76F8E;
}
